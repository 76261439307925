import React from 'react';
import Alert from 'react-bootstrap/Alert';
import {useState} from "react";

const Footer = () => {
    const date = new Date();
    const emailAddress = "zajda229@gmail.com"
    const copyEmailToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(emailAddress);
             // Optional feedback to the user

            handleShowAlert();
        } catch (err) {
            console.error("Failed to copy: ", err); // Error handling
        }
    }

    const [showAlert, setShowAlert] = useState(false);
    const handleShowAlert = () => {
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    }

    return (
        <div className="footer fixed-bottom">
            <Alert variant="success" className="col-3 mb-lg-3" show={showAlert}>Email address copied to clipboard</Alert>
            <p>Created by <a href="#!" className="footerLink" onClick={copyEmailToClipboard}>Zajda229</a> &copy;{date.getFullYear()}</p>
        </div>
    );
}

export default Footer;