import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import MainContainer from "./Components/FinApp/MainContainer";
import Home from "./Components/Home/Home";
import NavBar from "./Components/NavBar";
import TiskMain from "./Components/Tisk/TiskMain";
import Footer from "./Components/FinApp/Footer";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <NavBar />
      <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />}  />
                <Route path="/financeApp" element={<MainContainer />} />
                {/*<Route path="/tisk" element={<TiskMain />} />*/}
                {/*<Route path="/tisk/admin" element={<TiskMain />} />*/}
            </Routes>
      </BrowserRouter>
      <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
