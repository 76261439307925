import TiskForm from "./TiskForm";
import TiskHeader from "./TiskHeader";

function TiskMain() {
    return (
        document.title = "3D Tisk",
        <div>
            <TiskHeader />
            <TiskForm />
        </div>
    );
}

export default TiskMain;