import React from 'react';
import HomeContent from "./HomeContent";
import HomeThree from "./HomeThree";

const Home =()=> {


    return (
        document.title = "Home",
            <div>
        <div className="centerBox">
            <h1 className="mainHeading">Zajda229</h1>
            </div>
                <div className="homeContent">
                <HomeContent />
                </div>
                {/*<HomeThree />*/}
            </div>
    );
}

export default Home;