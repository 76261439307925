import {useState, useEffect} from "react";
import Header from "./Header";
import InputExpense from "./InputExpense";
import ExpenseList from "./ExpenseList";
import {v4 as uuidv4} from "uuid"
import {Navbar} from "react-bootstrap";


//Main component for the app
const MainContainer = () =>{
    //hooks for balance and expenses
    const [balance, setBalance] = useState(balanceInit);
    const [expenses, setExpense] = useState(getInitExpense);

    //currency variable
    const currency = "CZK";


    //initializing expenses from local storage
    function getInitExpense(){
        const temp=localStorage.getItem("expense");
        const savedTodos = JSON.parse(temp)
        return savedTodos || []
    }

    //initializing balance from local storage
    function balanceInit(){
        const temp = localStorage.getItem("balance");
        const savedBalance = JSON.parse(temp);
        return savedBalance || 0;
    }

    //function to add expense
    const addExpense = (title, date ,amount) => {
        setBalance(prevBalance => prevBalance + parseInt(amount));
        //setExpense(prevExpenses => [{id: uuidv4(), date: date, title: title, amount: amount}, ...prevExpenses])
        setExpense([...expenses, {id: uuidv4(), date: date, title: title, amount: amount}]);

    }

    //saving to local storage
    useEffect(() => {
        //saving to local storage
        const tempBalance = JSON.stringify(balance);
        const tempExpenses = JSON.stringify(expenses);
        localStorage.setItem("balance", tempBalance);
        localStorage.setItem("expense", tempExpenses);


        //sorting by date in descending order

        //const sortedExpenses = [...expenses].sort((a, b) => new Date(b.date) - new Date(a.date));
        //setExpense(sortedExpenses);
    }, [balance, expenses]);


    //function to delete expense
    const deleteExpense = (id)=>{
        setExpense([...expenses.filter(expense => {return expense.id !== id})]);
        setBalance(prevBalance => prevBalance - parseInt(expenses.find(expense => expense.id === id).amount));
        console.log(id);
    }

    //function to edit expense
    function editExpense(id, title, date, amount){
        const tempExpenses = [...expenses];
        const index = tempExpenses.findIndex(expense => expense.id === id);
        tempExpenses[index] = {id: id, title: title, date: date, amount: amount};
        setExpense(tempExpenses);
        setBalance(prevBalance => prevBalance - parseInt(expenses.find(expense => expense.id === id).amount) + parseInt(amount));
    }


    //dev function to delete all expenses
    function deleteAll(){
        setExpense([]);
        setBalance(0);
    }


    //returning the main component with all the child components
   return (
       document.title = "Finance App",
       <div>
           <Navbar/>
           <Header balanceProps={balance} currencyProps={currency}/>
           <InputExpense addExpenseProps={addExpense}/>
           <ExpenseList expenses={expenses} deleteExpense={deleteExpense} editExpanse={editExpense}/>
           <button onClick={deleteAll}>Delete All</button>
       </div>
   );
}

export default MainContainer;