import React from 'react';
import {useState} from 'react';
import {Image, Navbar} from "react-bootstrap";
import favicon from "../imgs/favicon.png";


const NavBar = () => {

    return(

      <Navbar className="navbar shadow mb-lg-3" >
          <Navbar.Brand href="/" className="navbarBrand"><Image src={favicon} className="navBarImg"/></Navbar.Brand>
          <Navbar.Brand href="/financeApp" className="navbarBrand">Finance App</Navbar.Brand>
          {/*<Navbar.Brand href="/tisk" className="navbarBrand">3D Tisk</Navbar.Brand>*/}
      </Navbar>
    );
}

export default NavBar;