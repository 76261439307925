import React from "react";
import {useState, useEffect} from "react";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

//Header component
const Header = (props) =>{
    //get currency and balance from props in MainContainer
    const currency = props.currencyProps;
    const balance = props.balanceProps;
    //pattern which will set balance to show separate three digits
    const balancePattern = balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    //set theme of div based on balance using Bootstrap themes
    const [theme, setTheme] = useState("bg-success");

    //useEffect to change theme based on balance
    useEffect(() => {
        if(balance < 0){
            setTheme("danger");//Red text
        } else if(balance === 0){
            setTheme("secondary");//Orange text
        } else {
            setTheme("success");//Green text
        }
    }, [balance]);

    //returning the header component with balance and currency
    return (
        <div className="App-header">
            <h2 className={`text-${theme}`}>Balance: {balancePattern} {currency}</h2>
        </div>
    );

}

export default Header;