import React from 'react';
import Expense from './Expense';

//Component that lists all expenses
const ExpenseList = (props) =>{

    //returning the list of expenses
    return (
        <div>
            <table className="table-list">
                <tr>
                {props.expenses.map(expense => (//mapping through the expenses and returning each expense
                    <Expense key={expense.id} handleChange={props.editExpanse} expense={expense} deleteExpense={props.deleteExpense} />//passing the expense to the Expense component
                ))}
                </tr>
            </table>
        </div>
    );

}

export default ExpenseList;