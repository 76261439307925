import React, {useState} from 'react';

//Component for defining expenses
const Expense = (props) => {

    //get the expense details from props
    const { id, title, date, amount } = props.expense;

    //hooks for editing, hiding and updating the expense
    const [hidden, setHidden] = useState(true);
    const [editing, setEditing] = useState(false);
    const [editTitle, setEditTitle] = useState(title);
    const [editDate, setEditDate] = useState(date);
    const [editAmount, setEditAmount] = useState(amount);

    //function to handle editing of the expense
    const handleEdit = () => {
        setEditing(true);
        setEditTitle(title);
        setEditDate(date);
        setEditAmount(amount);
    }

    //function to handle updating the expense
    const handleUpdateDone = event => {
        if (event.key === "Enter") {

            //checking if the input fields are not empty and the amount is a number
            if(isNaN(editAmount) || editTitle.trim() === "" || editAmount.trim() === ""){
                //if the input fields are empty, alert the user and set editing to false
                alert("Please enter a valid input");
                setEditing(false)

        }else{
                //updating the expense
                props.handleChange(id, editTitle, editDate, editAmount);
                setEditing(false);
            }
        }
    }

    //function to handle mouse over for showing delete button
    const handleMouseOver = () => {
        setHidden(false);
    }

    //function to handle mouse leave for hiding delete button
    const handleMouseLeave = () => {
        setHidden(true);
    }


    //returning the expense row
    return (
        <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} className="expenseRow">
            <th className="expenseRowDel" hidden={hidden} onClick={() => props.deleteExpense(id)}>X</th>

            <th className="titleTH" onDoubleClick={handleEdit}>
                {editing ? <input type="text" value={editTitle} onChange={(e) => setEditTitle(e.target.value)} onKeyDown={handleUpdateDone} /> : title}
            </th>

            <th className="amountTH" onDoubleClick={handleEdit}>
                {editing ? <input type="number" value={editAmount} onChange={(e) => setEditAmount(e.target.value)} onKeyDown={handleUpdateDone} /> : amount}
            </th>

            <th className="dateTH" onDoubleClick={handleEdit}>
                {editing ? <input type="date" value={editDate} onChange={(e) => setEditDate(e.target.value)} onKeyDown={handleUpdateDone} /> : date}
            </th>
        </div>
    )
}

export default Expense;