import React from 'react';
import {useState} from 'react';

//InpuExpense component to add new expenses
const InputExpense = (props) => {

    //hooks for expense title, amount and date
    const [expenseTitle, setExpenseTitle] = useState("");
    const [expenseAmount, setExpenseAmount] = useState(0);
    const [expenseDate, setExpenseDate] = useState("");
    const maxNum = 999999999999;


    //functions to handle title change in input fields
    const handleTitleChange = (e) => {
        setExpenseTitle(e.target.value);
    }

    //functions to handle amount change in input fields
    const handleAmountChange = (e) => {
        setExpenseAmount(e.target.value);
    }

    //functions to handle date change in input fields
    const handleDateChange = (e) => {
        setExpenseDate(e.target.value);

    }


    //function to handle submit of the form to add new expense
    const handleSubmit = (e) => {
        e.preventDefault();//preventing default form submission

        //checking if the input fields are not empty and checks if there are not zeroes before number
        if(expenseTitle.trim() && expenseAmount !== 0){

            //if the date is empty, setting the date to current date
            let finalExpenseDate = expenseDate;

            if(expenseDate === null || expenseDate === ""){
                finalExpenseDate = new Date().toISOString().slice(0,10);
            }
            props.addExpenseProps(expenseTitle, finalExpenseDate, parseInt(expenseAmount, 10));

            //resetting the input fields
            setExpenseTitle("");
            setExpenseAmount(0);
            setExpenseDate("");
        }else{
        }
    }

    //returning the form to add new expense
    return (
        <div>
            <form onSubmit={handleSubmit} className="expenseInput">
                <input className="expenseInputDef" type="date" onChange={handleDateChange} value={expenseDate}/>
                <input className="expenseInputDef" type="text" placeholder="Title" onChange={handleTitleChange} value={expenseTitle} required/>
                <input className="expenseInputDef" step=".01" type="number" max={maxNum} placeholder="Amount" onChange={handleAmountChange} value={expenseAmount} required/>
                <button className="expenseInputSubmit" type="submit" >ADD</button>
            </form>
        </div>
    );
}

export default InputExpense;